<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="choice_modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ modal_title }}</span>

                        <button type="button" class="btn-close" @click="close_modal" aria-label="Close modal">x</button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">
                        <DropDownList idVal="choice" :nameVal="select_name" classVal="iconic-select channel-select" showDefault="false" :val="last_value" :data="content_data" />
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="save_function" aria-label="Store">{{ lang.save }}</button>
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="close_modal" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import * as Utility from './../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    import DropDownList from './elements/DropDownList'

    export default {
        name: 'ChoiceModal',
        components: {
            DropDownList
        },
        props: {
            content_data: [],
            last_value: String,
            select_name: String,
            modal_title: String,
            close_modal: String,
            save_function: String
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: ""
            }
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header .btn-close:active{
        position: relative;
        color: #248767;
        top: 1px;
    }
    .modal-header button.btn-close:focus {
        outline: none;
    }
    span.content-header {
        font-size: 23px;
        position: relative;
        top: 5px;
    }

    #choice_modal {
        width: 350px;
        height: 247px; 
        position: unset;        
        transition: width 1s, height 1s;
    }
    #choice_modal span.content-header {
        font-size: 1rem !important;
    }
    #choice_modal .modal-body {
        text-align: center;
    }
    #choice_modal select {
        margin-top: 15px;
    }
</style>