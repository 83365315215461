<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="sidebar-sticky pt-3">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a :class="dashboard_link_class" href="#">
              <i class="ri-home-3-line icon-sidebar"></i>
              Dashboard
            </a>
          </li> 
          
          <template v-if="is_categ_mang == 'true' && is_store_mang!='true' && is_runner != 'true' ">
            <li class="nav-item">
              <a :class="products_link_class" :href="siteroot +'products.html'"> 
                <i class="ri-stack-line icon-sidebar"></i>
                {{ lang.products }}
              </a>
            </li>
          </template>

          <template v-if="is_runner == 'true' && is_categ_mang != 'true' && is_store_mang!='true'">
            <li class="nav-item">
              <a :class="queque_link_class" :href="siteroot +'quequeorder.html'">
                <i class="ri-list-check icon-sidebar"></i>
                {{ lang.queue_orders }}
              </a>
            </li>
          </template>

          <template v-if="is_runner == 'true' && is_categ_mang == 'true' && is_store_mang!='true'">
            <li class="nav-item">
              <a :class="products_link_class" :href="siteroot +'products.html'"> 
                <i class="ri-stack-line icon-sidebar"></i>
                {{ lang.products }}
              </a>
            </li>
            <li class="nav-item">
              <a :class="queque_link_class" :href="siteroot +'quequeorder.html'">
                <i class="ri-list-check icon-sidebar"></i>
                {{ lang.queue_orders }}
              </a>
            </li>
          </template>

          <template v-if="is_store_mang == 'true'">
            <li class="nav-item">
              <a :class="orders_link_class" :href="siteroot +'orders.html'">
                <i class="ri-file-copy-2-line icon-sidebar"></i>
                {{ lang.orders }}
              </a>
            </li>
            <li class="nav-item">
              <a :class="products_link_class" :href="siteroot +'products.html'"> 
                <i class="ri-stack-line icon-sidebar"></i>
                {{ lang.products }}
              </a>
            </li>
            <li class="nav-item">
              <a :class="queque_link_class" :href="siteroot +'quequeorder.html'">
                <i class="ri-list-check icon-sidebar"></i>
                {{ lang.queue_orders }}
              </a>
            </li>
            <li class="nav-item">
              <a :class="manage_shop_link_class" :href="siteroot +'manage_store.html'"> <!-- href="#" -->
                <i class="ri-restaurant-line icon-sidebar"></i>
                {{ lang.manage_shop }}
              </a>
            </li> 
            <li v-if="insert_dev_components == 'true'" class="nav-item">
              <a :class="tablemanager_link_class" :href="siteroot +'tablemanager.html'">
                <i class="ri-function-line icon-sidebar"></i>
                {{ lang.tablemanager }}
              </a>
            </li>
            <li v-if="is_customer_mang == 'true'" class="nav-item">
              <a :class="customers_link_class" :href="siteroot +'customers.html'">
                <i class="ri-user-add-line icon-sidebar"></i>
                {{ lang.manage_customer }}
              </a>
            </li>
            <li v-if="is_customer_mang == 'true'" class="nav-item">
              <a :class="cluster_link_class" :href="siteroot +'cluster-area.html'">
                <i class="ri-organization-chart icon-sidebar"></i>
                {{ lang.customer_cluster }}
              </a>
            </li>
            <li class="nav-item">
              <a :class="settings_link_class" href="#"> <!-- :href="siteroot +'settings.html'" -->
                <i class="ri-settings-4-line icon-sidebar"></i>
                {{ lang.settings }}
              </a>
            </li>
          </template>

          <li class="nav-item logout-li">
            <a class="nav-link" :href="getUrlLogout()">
              <i class="ri-logout-box-line icon-sidebar"></i>
              {{ lang.sign_out }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
</template>

<script>
    import * as Utility from '../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'SidebarMenu',
        setup() { 
          const language = useGettext();
          return {
              language,
          };
        },
        beforeMount() {
          this.is_runner = localStorage.getItem('runner');
          this.is_categ_mang = localStorage.getItem('categ_m');
          this.is_store_mang = localStorage.getItem('store_m');
          this.is_customer_mang = localStorage.getItem('customer_m');
          console.log(this.is_customer_mang);
          if(window.location.href.includes("orders.html")){
            this.orders_link_class = "nav-link active";

          }else if(window.location.href.includes("dashboard.html")){
            this.dashboard_link_class = "nav-link active";
          
          }else if(window.location.href.includes("quequeorder.html")){
            this.queque_link_class = "nav-link active";
          
          }else if(window.location.href.includes("tablemanager.html")){
            this.tablemanager_link_class = "nav-link active";

          }else if(window.location.href.includes("products.html")){
            this.products_link_class = "nav-link active";

          }else if(window.location.href.includes("customers.html")){
            this.customers_link_class = "nav-link active";

          }else if(window.location.href.includes("settings.html")){
            this.settings_link_class = "nav-link active";
            
          }else if(window.location.href.includes("manage_store.html")){
            this.manage_shop_link_class = "nav-link active";

          }else if(window.location.href.includes("cluster-area.html")){
            this.cluster_link_class = "nav-link active";
          }
        },
        mounted() {
          this.lang = Utility.getLanguage(this.language);
        },
        data(){
          return{
            lang: "",
            is_runner: false,
            is_categ_mang: false,
            is_store_mang: false,
            is_customer_mang: false,
            insert_dev_components: process.env.VUE_APP_INSERT_DEV_COMPONENTS,
            siteroot: process.env.VUE_APP_SITEROOT,
            tablemanager_link_class: "nav-link", 
            manage_shop_link_class: "nav-link",
            customers_link_class: "nav-link",
            dashboard_link_class: "nav-link",
            products_link_class: "nav-link",
            settings_link_class: "nav-link",
            cluster_link_class: "nav-link",
            orders_link_class: "nav-link",
            queque_link_class: "nav-link"
          }
        },
        methods: {
          getUrlLogout(){
            return this.siteroot + localStorage.getItem('url_part');
          }
        }
    }
</script>

<style>
    #sidebarMenu {
        padding: 0px;
        z-index: 200;
        position: fixed;
        padding-left: 5px;
        background-color: #f8f9fa!important;
        border-right: 1px solid rgba(0,0,0,.1)
    }
    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 42px);
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .icon-sidebar {
        font-size: 20px;
        margin-right: 5px;
        position: relative;
        top: 4px;
    }
    .logout-li {
        display: none;
    }
    li.nav-item a {
        color: #959595 !important;
    }
    li.nav-item a:hover {
        color: #343434 !important;
    }
    li.nav-item a.active {
        color: #2EB085 !important;
    }
    li.nav-item a.active:hover {
      color: #248767 !important;
    }

    @media screen and (max-width: 767px) {
        .logout-li {
            display: block;
        }
    }
</style>
